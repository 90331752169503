/* You can add global styles to this file, and also import other style files */
@import 'colors';

html,
body {
  background-color: $darkblue;
  color: $lightgrey;
}

a {
  color: $orange;
}

a:hover {
  color: $lightorange;
}

.badge-primary {
  background-color: $orange;
}

a.badge-primary:focus,
a.badge-primary:hover {
  background-color: $orange;
}

a.badge-primary.focus,
a.badge-primary:focus {
  box-shadow: none;
}

.btn-primary {
  background-color: $orange;
  border-color: $orange;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: $lightorange;
  border-color: $lightorange;
}

.btn-primary:hover {
  background-color: $lightorange;
  border-color: $lightorange;
}
